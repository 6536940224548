import React, { Component } from "react";
import Range from "./ranges/screen-range";
import Total from "./total";

export default class Calculator extends Component {
    constructor(props) {
        super(props);
            this.state = {
                total:500000,
                percent:0,
                percentages:0,
                mounth:12,
                years:1,
                credit:530000,
                mounthlyPayment:44166,
                cashback:65000,
                rate:6

            };
            this.setTotal = this.setTotal.bind(this);
            this.setPercent = this.setPercent.bind(this);
            this.setMounth = this.setMounth.bind(this);
            this.calcPercentages = this.calcPercentages.bind(this);
            this.calcCredit = this.calcCredit.bind(this);

    }
    setTotal(value) {
        this.setState({
            total:value
        })
    }
    calcPercentages() {
        this.setState({
            percentages:Math.ceil(this.state.total * (this.state.percent/100)) 
        })
    }
    setPercent(value) {
        this.setState({
            percent:value
        })
    }
    setMounth(value) {
        this.setState({
            mounth:value
        })
        // this.calcCredit()
        
    }
    calcSectionHeight() {
        console.log('calc');
        const sectionsWrappers = document.querySelectorAll('.section-wrapper');
        sectionsWrappers.forEach(item=>{
            const height = item.offsetHeight;
            item.closest('.section').style.height = height+"px";
        })
    }
    formatNumber(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    calcYears(value) {
        this.setState({
            years:Math.floor((this.state.mounth / 12) *20)/20
        })
    }
    calcCredit() {
        this.setState({
            credit: this.state.mounthlyPayment * this.state.mounth
        })

    }
    calcPayment() {
        let mounthlyRate = this.state.rate/100/12;
        let mounthCount = this.state.mounth;
        let total = this.state.total - this.state.percentages;

        this.setState({
            mounthlyPayment:(total *mounthlyRate) / (1 - Math.pow(1+mounthlyRate, -mounthCount))
        })
    }
    calcCashback() {
        this.setState({
            cashback:Math.min((this.state.credit/100)*13, 260000)
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.total !== this.state.total || 
            prevState.percent !== this.state.percent || 
            prevState.mounth !== this.state.mounth||
            prevState.percentages!==this.state.percentages||
            prevState.years!== this.state.years||
            prevState.credit!== this.state.credit||
            prevState.mounthlyPayment!==this.state.mounthlyPayment||
            prevState.cashback!== this.state.cashback) {
                this.calcPercentages();
                this.calcYears();
                this.calcCredit();
                this.calcPayment();
                this.calcCashback();
        }
    }
    componentDidMount(){
        this.calcSectionHeight();
    }
    render() {
        let {years,total,percent,percentages,mounth,mounthlyPayment,cashback,rate,credit} = this.state
        let yearsLabel = "";
        if(years==1 || years==21) {
            yearsLabel = "год"
        }
        else if(years<21 && years>=5) {
            yearsLabel = "лет"
        }
        else if(years>21&&years<25) {
            yearsLabel= "года"
        }
        else if(years>=25) 
            yearsLabel ="лет"

        else {
            yearsLabel = "года"
        }
        return(
            <>
                
               <div className="calculator-first-col">
                    <p className="title">Рассчитайте <br></br>ипотеку</p>
                    <div className="ranges-wrapper">
                        <Range max ="6000000" id={"1-"+this.props.id} value="500000" method ={this.setTotal} step="50000"label="Стоимость имущества:" min="500000" minLabel="500 тыс. ₽" renderedValue={total} maxLabel="6 млн ₽" renderLabel=" ₽"/>
                        <Range max ="49" id={"2-"+this.props.id} value="0" method ={this.setPercent} label="Первоначальный взнос" min="0"minLabel="0%" renderedValue={percentages} maxLabel="49%" renderLabel=" ₽" percent={percent + " %"}/>
                        <Range max ="360" id={"3-"+this.props.id} value="12" method ={this.setMounth} label="Срок договора" min="12" step="6" minLabel="12 мес" renderedValue={years} maxLabel="30 лет" renderLabel={" "+yearsLabel}/>
                    </div>
               </div>
               <div className="calculator-second-col">
                <Total mounthlyPayment={mounthlyPayment} cashback={cashback} credit={credit} percent= {rate} method={this.formatNumber}/>
               </div>
            </>
        )
    }

}